import React, {useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import QRCodeGenerator from '../QRCodeGenerator';
import {toPng} from "html-to-image";
import download from 'downloadjs';
import MatrixBackground from "./components/MatrixBackground";
import {Global} from "../config/Global";

const ShareCard: React.FC = () => {
    const location = useLocation();
    const data = (location.state as { data: string })?.data;
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
     const handleDownload = async () => {
        if (componentRef.current === null) {
            return;
        }

        try {
            const dataUrl = await toPng(componentRef.current);
            download(dataUrl, 'component-image.png');
        } catch (err) {
            console.error('Failed to capture image', err);
        }
    };

    const copyToClipboard = async () => {
        const text =  Global.sharedLink+data;
        try {

            await navigator.clipboard.writeText(text);
            setCopySuccess(true);
            const timer = setTimeout(() => {
                setCopySuccess(false);
            }, 2000);

            // Clean up the timer when the component unmounts
            return () => clearTimeout(timer);
        } catch (err) {
            setCopySuccess(false);
        }
    };

    function goToEncrypt() {
        navigate('/', {state: {data}, replace: true});
    }

    return (
        <div style={{
            maxWidth: '300px',
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            textAlign: 'center'
        }}>

            <h2 style={{color: "whitesmoke"}}>Share it!</h2>
            <div ref={componentRef}>
                <QRCodeGenerator link={Global.sharedLink+data}/>
            </div>
            <button onClick={copyToClipboard} style={{marginTop: '20px'}}>
                {copySuccess ? "Copied!" : "Copy"}
            </button>
            <button onClick={handleDownload} style={{marginTop: '20px', marginLeft: "20px"}}>
                Download
            </button>

            <p style={{color: 'lightgoldenrodyellow', fontSize: '12px', marginTop: '10px'}}>
                Upon access or after 24 hours, your data will be automatically deleted.
            </p>
            <button onClick={goToEncrypt} style={{marginTop: '20px', backgroundColor: 'green'}}>
                Return To Home
            </button>
        </div>
    );
};

export default ShareCard;
