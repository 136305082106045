// src/components/SecureForm.tsx
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useGetSessionIdQuery, usePostGetDataMutation} from '../network/apiService';
import './SecureForm.css';
import MatrixBackground from "./components/MatrixBackground"; // Make sure to create a corresponding CSS file


const SecureGetForm: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [dataRevelaed, setDataRevelaed] = useState('');
    const [postGetData] = usePostGetDataMutation();
    const {isLoading} = useGetSessionIdQuery({});
    const [access, setQueryString] = useState<string | null>(null);
    const navigate = useNavigate();
    const [revealing, setRevealing] = useState(false)
    const goToDataReveal = (data: string) => {
        navigate('/DataReveal', {state: {data}, replace: true});
    };

    useEffect(() => {
        // Get the current URL's query string
        let queryString = window.location.href;
        const pathArr: string[] = queryString.split('?');

        // Set the extracted string to the state
        setQueryString(pathArr[1]);
    }, []);

    const handleSubmit = async () => {

        if (access != null) {
            if (!username) {
                toast.error("Enter username");
                return;
            }
            if (!password) {
                toast.error("Enter password");
                return;
            }
            setRevealing(true);
            await postGetData({username, password, access}).unwrap().then(result => {
                setDataRevelaed(result.data)
                goToDataReveal(result.data)
                setRevealing(false)
            }).catch(error => {
                toast.error("Invalid Credentials");
                setRevealing(false)
            })
        }
    };

    return (
        <div className="container">

            <div className="form">
                {isLoading === false ? (<div className="lock-icon">🔒</div>) : null}
                <h2>Data Will Be Deleted Once Reveal It</h2>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        placeholder="Enter your username here"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        placeholder="Enter your password here"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <p>Upon access or after 24 hours, your data will be automatically deleted.</p>
                <button type="submit" onClick={() => handleSubmit()}>{revealing ? "Revealing.." : "Reveal"}</button>

            </div>
        </div>
    );
};

export default SecureGetForm;
