// src/components/SecureForm.tsx
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useGetSessionIdQuery, usePostShareDataMutation} from '../network/apiService';
import MatrixBackground from './components/MatrixBackground';
import './SecureForm.css';

const SecureForm: React.FC = () => {
    const [inputData, setSensitiveInfo] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [postShareData] = usePostShareDataMutation();
    const {data} = useGetSessionIdQuery({});
    const [securing, setSecuring] = useState(false);

    const handleSubmit = () => {
        setSecuring(true);
        const sessionId = data?.data.toString();
        if (sessionId !== undefined && (password !== '' && username !== '' && inputData !== '')) {
            postShareData({username, password, sessionId, inputData})
                .unwrap()
                .then(result => {
                    if (result.data !== 'invalid') {
                        goToShareCard(result.data.link);
                        setSecuring(false);
                    } else {
                        toast.error('Invalid Session Id');
                        setSecuring(false);
                    }
                })
                .catch(error => {
                    toast.error(error);
                    setSecuring(false);
                });
        } else {
            toast.error('Missing Fields');
            setSecuring(false);
        }
    };

    const navigate = useNavigate();
    const goToShareCard = (data: string) => {
        navigate('/ShareCard', {state: {data}, replace: true});
    };

    return (
        <div className="container">

            <div className="form" aria-autocomplete="none">
                <div className="lock-icon">🔒</div>
                <h2 className={data ? "activated" : "pending-activation"}>
                    {data ? "Activated" : "Pending Activation"}
                </h2>
                <div className="form-group">
                    <label htmlFor="sensitiveInfo">Sensitive Info</label>
                    <textarea
                        id="sensitiveInfo"
                        value={inputData}
                        onChange={(e) => setSensitiveInfo(e.target.value)}
                        placeholder="Enter your sensitive information here"
                        rows={3}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="a1">Username</label>
                    <input
                        type="text"
                        id="a1"
                        name={`a1-${Math.random().toString(36).substr(2, 9)}`}
                        autoComplete="cc-given-name"
                        placeholder="Enter your username here"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        name={`p1-${Math.random().toString(36).substr(2, 9)}`}
                        placeholder="Enter your password here"
                        autoComplete="off"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <p style={{color: 'red'}}>Upon access or after 24 hours, your data will be automatically deleted.</p>
                <button type="submit" onClick={handleSubmit}>
                    {securing ? "Securing..." : "Secure And Share"}
                </button>
            </div>
        </div>
    );
};

export default SecureForm;
