import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom'; // Changed here
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import DataRevealForm from './screens/DataRevealForm';
import SecureForm from './screens/SecureForm';
import SecureGetForm from './screens/SecureGetForm';
import ShareCard from './screens/ShareCard';
import store from './store/store';
import MatrixBackground from "./screens/components/MatrixBackground";
import React from "react";


function App() {
    return (
        <Provider store={store}>
            <MatrixBackground/>
            <div className="App">
                <ToastContainer/>
                <Router>
                    <ConditionalRoutes/>
                </Router>
            </div>
        </Provider>
    );
}

function ConditionalRoutes() {
    const location = useLocation();
    const hasQueryString = location.search.includes("?");

    return (
        <Routes>
            <Route path="/" element={hasQueryString ? <SecureGetForm/> : <SecureForm/>}/>
            <Route path="/ShareCard" element={<ShareCard/>}/>
            <Route path="/SecureGetForm" element={<SecureGetForm/>}/>
            <Route path="/DataReveal" element={<DataRevealForm/>}/>
            <Route path="*" element={<SecureForm/>}/>

        </Routes>
    );
}

export default App;
