import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {apiService} from '../network/apiService';

// Combine reducers from different modules
const rootReducer = combineReducers({// Include the sound slice

    [apiService.reducerPath]: apiService.reducer,
});

// Define the root state of the application
export type RootState = ReturnType<typeof rootReducer>;

// Create the Redux store with middleware
const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiService.middleware),
});

export default store;
