import CryptoJS from 'crypto-js';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './SecureForm.css';
import {toast} from 'react-toastify';
import MatrixBackground from "./components/MatrixBackground"; // Make sure to create a corresponding CSS file
const DataRevealForm: React.FC = () => {
    const location = useLocation();
    const [decrypted, setDecrypted] = useState(false);
    const [dataRevealed, setDataRevealed] = useState('');
    const [pureData, setPureData] = useState('');
    const data = (location.state as { data: string })?.data;
    const [revealing, setRevealing] = useState(false);
    const navigate = useNavigate();
    const {REACT_APP_IV, REACT_APP_KEY} = process.env;
    useEffect(() => {
        setDataRevealed(data);
    }, [data]);

    const goToEncrypt = () => {
        navigate('/', {state: {data}, replace: true});
    };

    const handleDecrypt = () => {
        try {
            setRevealing(true);

            const iv = CryptoJS.enc.Hex.parse(REACT_APP_IV ?? "");
            const key = CryptoJS.enc.Hex.parse(REACT_APP_KEY ?? "");

            const encryptedBytes = CryptoJS.enc.Hex.parse(dataRevealed);

            const decryptedBytes = CryptoJS.AES.decrypt(
                {ciphertext: encryptedBytes} as any,
                key,
                {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                }
            );

            // Convert decrypted data to a UTF-8 string
            const decryptedTextUtf8 = decryptedBytes.toString(CryptoJS.enc.Utf8);
            if (decryptedTextUtf8) {
                setRevealing(false);
            } else {
                setRevealing(false);
            }
            setPureData(JSON.parse(decryptedTextUtf8).original[0].value);
            setDecrypted(true);
            setRevealing(false);
        } catch (e) {
            setRevealing(false);
            console.error(e);
        }
    };

    const copyToClipboard = async () => {
        const text = dataRevealed;
        try {
            await navigator.clipboard.writeText(text);
            toast.success("Copied");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    return (
        <div className="container">

            <div className="form">
                <div className="lock-icon">🔒</div>
                <h2>Data Will Be Deleted Once Reveal It</h2>
                {!decrypted && (
                    <input
                        type="password"
                        placeholder="****************"
                        id="password"
                        multiple={true}
                        value="************************************************"
                        disabled={true}
                    />
                )}
                {decrypted && (
                    <input
                        type="text"
                        value={pureData}
                        multiple={true}
                        disabled={true}
                        onClick={copyToClipboard}
                    />
                )}
                <p>Upon access or after 24 hours, your data will be automatically deleted.</p>
                <button type="submit" onClick={decrypted ? copyToClipboard : handleDecrypt}>
                    {decrypted ? "Copy" : "Decrypt"}
                </button>
                <p>
                    <a style={{color: 'green'}} href="#" onClick={goToEncrypt}>Go to Encrypt</a>
                </p>
            </div>
        </div>
    );
};

export default DataRevealForm;
