import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../store/store';
import {BaseResponse} from './BaseResponse';
import {API_ENDPOINTS, BASE_URL} from './Endpoints';

const customHeaders = {
    'x-api-key': 'a6bccdc2-2e2b-46e5-adb8-d9bf5fc24d7e',
    "Content-Type": "application/x-www-form-urlencoded"
};

export const apiService = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: async (headers, {getState}) => {
            // Explicitly define the type of state
            const state: RootState = getState() as RootState;


            // Set other custom headers
            Object.entries(customHeaders).forEach(([key, value]) => {
                headers.set(key, value);
            });

            return headers;
        },
        timeout: 30000,
    }),
    endpoints: builder => ({
        getSessionId: builder.query<BaseResponse<String>, {}>({
            query: () => {
                return {
                    url: API_ENDPOINTS.GENERATE_SESSION_ID,
                    method: 'POST',
                    body: {},
                };
            },
        }),
        postShareData: builder.mutation<
            BaseResponse<any>,
            { username: string; password: string, sessionId: string, inputData: string }
        >({
            query: ({username, password, inputData, sessionId}) => {
                const formBody = new URLSearchParams();
                formBody.append('dataUsername', username);
                formBody.append('dataPassword', password);
                formBody.append('sessionId', sessionId);
                formBody.append('inputData', inputData);
                return {
                    url: API_ENDPOINTS.SHARE_DATA,
                    method: 'POST',
                    body: formBody.toString(),
                };
            },
        }),
        postGetData: builder.mutation<
            BaseResponse<any>,
            { username: string; password: string, access: string }
        >({
            query: ({username, password, access}) => {
                const formBody = new URLSearchParams();
                formBody.append('dataUsername', username);
                formBody.append('dataPassword', password);
                formBody.append('access', access);
                return {
                    url: API_ENDPOINTS.GET_DATA,
                    method: 'POST',
                    body: formBody.toString(),
                };
            },
        }),
    }),
});

export const {
    useGetSessionIdQuery,
    usePostShareDataMutation,
    usePostGetDataMutation

} = apiService;
