import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { AssetsProvider } from "./config/AssetsProvider";

interface QRCodeGeneratorProps {
    link?: string;
}

interface QRCodeGeneratorState {
    link: string;
    qrSize: number;
}

class QRCodeGenerator extends React.Component<QRCodeGeneratorProps, QRCodeGeneratorState> {
    constructor(props: QRCodeGeneratorProps) {
        super(props);
        this.state = {
            link: props.link || '',
            qrSize: this.calculateQRCodeSize(),
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateQRCodeSize = this.updateQRCodeSize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateQRCodeSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateQRCodeSize);
    }

    calculateQRCodeSize() {
        return window.innerWidth / 4;
    }

    updateQRCodeSize() {
        this.setState({ qrSize: this.calculateQRCodeSize() });
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ link: event.target.value });
    }

    render() {
        return (
            <div style={styles.container}>

                {this.state.link && (
                    <div style={styles.qrContainer}>
                        <QRCode
                            eyeColor={"darkblue"}
                            fgColor={"black"}
                            qrStyle={"dots"}  // Use dots for a fancier look
                            size={this.state.qrSize}
                            value={this.state.link}

                            removeQrCodeBehindLogo={true}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',
         padding: '20px'
    },
    input: {
        marginBottom: '20px',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '80%',
        maxWidth: '400px'
    },
    qrContainer: {
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }
};

export default QRCodeGenerator;
